<template>
  <div class="container funding" v-loading="loading">
    <div class="base">
      <section class="contents banner">
        <article class="inner">
          <swiper class="swiper pc" :options="swiperOption">
            <swiper-slide class="slide1">
              <div class="left">
                <router-link
                  to="/survey/detail/623827ed176dfc6061594032"
                ></router-link>
              </div>
              <div class="right">
                <router-link
                  to="/survey/detail/62382995176dfc6061594033"
                ></router-link>
              </div>
            </swiper-slide>
            <swiper-slide class="slide2">
              <div class="center">
                <router-link
                  to="/survey/detail/6235451358da882b842a5a22"
                ></router-link>
              </div>
            </swiper-slide>
            <swiper-slide class="slide3"
              ><div class="left">
                <router-link
                  to="/survey/detail/6234865a58da882b842a5a1d"
                ></router-link>
              </div>
              <div class="right">
                <router-link
                  to="/survey/detail/624adfe4444c4838ee44686a"
                ></router-link></div
            ></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
          <swiper class="swiper mobile" :options="swiperOption2">
            <swiper-slide class="slide1"
              ><div class="left">
                <router-link
                  to="/survey/detail/623827ed176dfc6061594032"
                ></router-link>
              </div>
              <div class="right">
                <router-link
                  to="/survey/detail/62382995176dfc6061594033"
                ></router-link></div
            ></swiper-slide>
            <swiper-slide class="slide2"
              ><div class="center">
                <router-link
                  to="/survey/detail/6235451358da882b842a5a22"
                ></router-link></div
            ></swiper-slide>
            <swiper-slide class="slide3"
              ><div class="left">
                <router-link
                  to="/survey/detail/6234865a58da882b842a5a1d"
                ></router-link>
              </div>
              <div class="right">
                <router-link
                  to="/survey/detail/624adfe4444c4838ee44686a"
                ></router-link></div
            ></swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
        </article>
      </section>

      <section class="contents">
        <article class="inner flexB">
          <div class="early">
            <h3>인기 가격 공모</h3>
            <!-- <h4>프로젝트 오픈 전 사전 참여자 분들을 위한 혜택 &#127881;</h4> -->
            <div class="boxWrap">
              <div class="box" v-for="(data, i) in topList" :key="i">
                <router-link to @click.native="handleMove(data._id)">
                  <div class="title">
                    <button class="bookmark">
                      <div class="point">{{ data.point }}P</div>
                    </button>
                    <p>
                      {{
                        data.category == "SPECIAL"
                          ? "스페셜"
                          : data.category == "TECH"
                          ? "테크/가전"
                          : data.category == "FASHION"
                          ? "패션/잡화"
                          : data.category == "BEAUTY"
                          ? "뷰티"
                          : data.category == "FOOD"
                          ? "푸드"
                          : data.category == "HOMELIVING"
                          ? "홈리빙"
                          : data.category == "TRIP"
                          ? "여행/레저"
                          : data.category == "SPORTS"
                          ? "스포츠/모빌리티"
                          : data.category == "CULTURE"
                          ? "공연/컬쳐"
                          : data.category == "KIDS"
                          ? "베이비/키즈"
                          : ""
                      }}
                    </p>
                    <h4>{{ data.title }}</h4>
                  </div>
                  <div class="benefit">
                    <p>
                      시작가<span>{{ numberFormat(data.startAmount) }}원 </span>
                    </p>

                    <p class="primary">
                      현재가<span
                        >{{ numberFormat(Math.round(data.avgAmount)) }}원
                      </span>
                    </p>

                    <span>{{ data.surveyers.length }}명</span>
                    <span v-if="data.limitType == 'PERIOD'"
                      >{{ moment(data.endAt).fromNow() }} 마감</span
                    >
                    <span v-if="data.limitType == 'COUNT'"
                      >{{ data.userCount }}명</span
                    >
                  </div>
                  <img :src="`${data.thumbnail}`" alt="" />

                  <div class="coupon">
                    <p>* {{ data.couponInfo }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
          <div class="rank">
            <!-- <h3>Live Poll</h3>
            <h4>* 얼마니 가격조사를 원하는 아이템 하나를 선택해 주세요.</h4>
            <ul>
              <li>
                <label
                  ><input
                    type="radio"
                    name="poll_item"
                    id="item1"
                  /><span></span><em>새우깡</em></label
                >
              </li>
              <li>
                <label
                  ><input
                    type="radio"
                    name="poll_item"
                    id="item2"
                  /><span></span><em>세계여행 비용</em></label
                >
              </li>
              <li>
                <label
                  ><input
                    type="radio"
                    name="poll_item"
                    id="item3"
                  /><span></span><em>박카스</em></label
                >
              </li>
              <li>
                <label
                  ><input
                    type="radio"
                    name="poll_item"
                    id="item4"
                  /><span></span><em>편의점 알바 시간당 급여</em></label
                >
              </li>
              <li>
                <label
                  ><input
                    type="radio"
                    name="poll_item"
                    id="item5"
                  /><span></span>초등생 영어학원비</label
                >
              </li>
            </ul>
            <div class="buttonWrap">
              <button>투표하기</button>
              <button>결과보기</button>
            </div> -->
            <h3>최근 가격 공모</h3>
            <ul>
              <li v-for="(data, i) in postList" :key="i">
                <router-link
                  to
                  @click.native="handleMove(data._id)"
                  class="flexB"
                >
                  <h3>{{ i + 1 }}</h3>
                  <div>
                    <p class="bold">{{ data.title }}</p>
                    <span
                      ><span
                        :class="{
                          smaller:
                            Math.round(data.avgAmount).toString().length > 6,
                        }"
                        >{{ numberFormat(Math.round(data.avgAmount)) }}원</span
                      >
                      <em v-if="data.limitType == 'PERIOD'"
                        >{{ moment(data.endAt).fromNow() }} 마감</em
                      >
                      <em v-if="data.limitType == 'COUNT'"
                        >{{ data.userCount }}명</em
                      >
                    </span>
                  </div>
                  <img :src="`${data.thumbnail}`" alt="" />
                </router-link>
              </li>
            </ul>
          </div>
        </article>
      </section>
      <section class="contents">
        <article class="inner">
          <Card-List :category="'SPECIAL'" ref="card" />
        </article>
        <article class="inner">
          <Card-List :category="'TECH'" ref="card" />
        </article>
        <article class="inner">
          <Card-List :category="'FASHION'" ref="card" />
        </article>
        <article class="inner">
          <Card-List :category="'FOOD'" ref="card" />
        </article>
        <article class="inner">
          <Card-List category="HOMELIVING" ref="card" />
        </article>
        <article class="inner">
          <Card-List category="TRIP" ref="card" />
        </article>
        <article class="inner">
          <Card-List category="SPORTS" ref="card" />
        </article>
        <article class="inner">
          <Card-List category="CULTURE" ref="card" />
        </article>
        <article class="inner">
          <Card-List category="KIDS" ref="card" />
        </article>
        <article class="inner">
          <Card-List category="BEAUTY" ref="card" />
        </article>
        <article class="inner">
          <Card-List status="FINISH" ref="card" />
        </article>
      </section>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { fetchPostList, fetchTopPostList } from "@/api/post";
import { fetchBanners } from "@/api/index";
import CardList from "@/components/contents/CardList";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { format } from "@/mixins/format";

export default {
  mixins: [format],
  name: "swiper-example-loop",
  title: "Loop mode / Infinite loop",
  components: {
    Swiper,
    SwiperSlide,
    CardList,
  },

  data() {
    return {
      moment: moment,
      loading: false,
      startYmd: "",
      postList: [],
      images: [],
      topList: [],
      earlybirdList: [],
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 7000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption2: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        autoplay: {
          delay: 7000,
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        // breakpoints: {
        //   0: {
        //     slidesPerView: 1.1,
        //     spaceBetween: 20,
        //   },
        //   500: {
        //     slidesPerView: 1.4,
        //   },
        //   768: {
        //     slidesPerView: 2.2,
        //   },
        //   1024: {
        //     slidesPerView: 2.2,
        //   },
        // },
      },
    };
  },
  created() {
    this.$store.dispatch("SET_NAVBAR", 8);
  },
  computed: {
    ...mapState(["userId"]),
  },
  mounted() {
    this.loading = true;
    this.getBanners();
    this.getPostList();
  },
  methods: {
    handleMove(id) {
      this.$router.push({ name: "surveyDetail", params: { id: id } });
    },
    getBanners() {
      fetchBanners().then((res) => {
        if (res.status == 200) {
          this.images = res.data.data;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
    async getPostList() {
      let params = {
        latest: true,
        limit: 5,
      };
      fetchPostList(params).then((res) => {
        if (res.status == 200) {
          this.postList = res.data.postList;
          this.loading = false;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
      fetchTopPostList().then((res) => {
        if (res.data.status == 200) {
          this.topList = res.data.data;
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
